//
// 色
//

export const coffeeColor = '#7b5544';
export const teaColor = '#b50000';

export const titleColor = coffeeColor;
export const titleHoverColor = teaColor;
export const asideTextColor = '#666';
export const subTitleColor = asideTextColor;
export const linkColor = coffeeColor;
export const linkHoverColor = teaColor;
export const baseTextColor = '#333';
export const metaTextColor = '#999';
export const borderColor = '#ddd';
export const pageDividerColor = '#eee';
export const readmoreColor = coffeeColor;

//
// アニメーション
//

export const animationDuration = 0.3; // 秒
export const timingFunction = 'ease-in-out';

//
// ブレイクポイント
//

export const tabletBreakpoint = 768;
export const twoColumnsBreakpoint = 980;

//
//

export const maxWidthForOneColumn = 700;
export const maxWidthForTwoColumns = 1080;
