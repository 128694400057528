import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import React from 'react';

import { isNotNull, isNotUndefined } from '../utils/filter';
import TagList from './tag-list';

const TagCloud: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.TagCloudQuery>(graphql`
    query TagCloud {
      allMarkdownRemark(limit: 1000) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `);
  const allTags = _.uniq(
    data.allMarkdownRemark.edges
      .map(edge => edge.node.frontmatter?.tags || [])
      .flat()
      .filter(isNotUndefined)
      .filter(isNotNull)
      .sort()
  );

  return <TagList tags={allTags} />;
};

export default TagCloud;
