import { css } from '@emotion/core';
import { Link } from 'gatsby';
import _ from 'lodash';
import React from 'react';

import { linkColor, metaTextColor, pageDividerColor } from '../design/css-variables';

type Props = { tags: readonly string[] };

const TagList: React.FC<Props> = ({ tags }) => {
  return (
    <ul
      css={css`
        display: inline-flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          font-size: 1.4rem;
          line-height: 2.4rem;
          background-color: ${pageDividerColor};
          margin-right: 8px;
          margin-bottom: 8px;
          padding-left: 8px;
          padding-right: 8px;

          a {
            color: ${metaTextColor};
            text-decoration: none;
            box-shadow: none;
          }
          a:hover {
            color: ${linkColor};
            font-weight: bold;
          }
        }
      `}
    >
      {tags.map(tag => {
        const tagPath = `/tags/${_.kebabCase(tag)}/`;
        return (
          <li key={tag}>
            <Link to={tagPath}>{tag}</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default TagList;
