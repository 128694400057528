import '@fortawesome/fontawesome-svg-core/styles.css';

import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
// https://medium.com/@fabianterh/fixing-flashing-huge-font-awesome-icons-on-a-gatsby-static-site-787e1cfb3a18
import { config } from '@fortawesome/fontawesome-svg-core';
import emotionNormalize from 'emotion-normalize';
import React from 'react';
config.autoAddCss = false;

import {
  animationDuration,
  linkColor,
  linkHoverColor,
  maxWidthForOneColumn,
  maxWidthForTwoColumns,
  tabletBreakpoint,
  timingFunction,
  twoColumnsBreakpoint,
} from '../design/css-variables';
import Footer from './footer';
import Header from './header';
import Sidebar from './sidebar';

const Wrapper = styled.div``;

// 全体に適用するCSSをここで指定
const GlobalLayout: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <Wrapper>
      <Global
        styles={css`
          ${emotionNormalize}
          html {
            /* スマホでは基準サイズを少し小さくしておく（1remで8.8pt） */
            font-size: 55.5%;
          }
          body {
            font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
              'Hiragino Sans', Meiryo, sans-serif;
            line-height: 1.7;
            color: #333;
            font-size: 1.6rem;
            margin-left: 24px;
            margin-right: 24px;
          }
          @media screen and (min-width: ${tabletBreakpoint}px) {
            html {
              /* 文字をスマホよりちょっと大きく（1remで10pt） */
              font-size: 62.5%;
            }
            body {
              /* 両サイドのマージンを大きく */
              margin-left: 48px;
              margin-right: 48px;
            }
          }
          /* リンクホバー時にアニメーションさせる。 */
          a {
            color: ${linkColor};
            transition: color ${animationDuration}s ${timingFunction},
              font-weight ${animationDuration}s ${timingFunction};
            box-shadow: 0 -1px 0px 0px inset;
            text-decoration: none;
          }
          a:hover {
            color: ${linkHoverColor};
          }
          /* タイトル用にGoogle Fontsを利用する（利用する文字のみ） */
          @import url('https://fonts.googleapis.com/earlyaccess/hannari.css?text=${title}');
        `}
      />
      {children}
    </Wrapper>
  );
};

const Main = styled.main`
  margin-left: auto;
  margin-right: auto;
  max-width: ${maxWidthForOneColumn}px;

  @media screen and (min-width: ${twoColumnsBreakpoint}px) {
    display: flex;
    justify-content: space-between;
    max-width: ${maxWidthForTwoColumns}px;
  }
`;

const ContentContainer = styled.section`
  @media screen and (min-width: ${twoColumnsBreakpoint}px) {
    width: 69.81%;
  }
`;

const SidebarContainer = styled.aside`
  border-top: solid 2px #eee;
  padding-top: 60px;

  @media screen and (min-width: ${twoColumnsBreakpoint}px) {
    width: 22.77%;
    border-top: none;
    padding-top: 0;
  }
`;

type Props = {
  title: string;
  description: string;
  tableOfContents?: string;
};

const Layout: React.FC<Props> = ({ title, description, tableOfContents, children }) => {
  return (
    <GlobalLayout title={title}>
      <Header title={title} description={description} />
      <Main>
        <ContentContainer>{children}</ContentContainer>
        <SidebarContainer>
          <Sidebar tableOfContents={tableOfContents}></Sidebar>
        </SidebarContainer>
      </Main>
      <Footer />
    </GlobalLayout>
  );
};

export default Layout;
