import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { tabletBreakpoint, titleColor, titleHoverColor } from '../design/css-variables';

const SiteHeader = styled.header`
  margin-bottom: 0;
  @media screen and (min-width: ${tabletBreakpoint}px) {
    margin-bottom: 60px;
  }
  /* サイトのヘッダに表示するものは全てリンクに下線を付けない */
  a {
    text-decoration: none;
    box-shadow: none;
  }
`;

const SiteBranding = styled.div`
  padding-bottom: 40px;
  padding-top: 25px;
  @media screen and (min-width: ${tabletBreakpoint}px) {
    padding-bottom: 48px;
    padding-top: 40px;
  }
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const SiteTitle = styled.h1`
  font-family: 'Hannari', '游明朝', YuMincho, 'Hiragino Mincho ProN', Meiryo, serif;
  font-size: 1.9rem;
  font-weight: 400;
  /* h1 のマージンを打ち消し */
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.7;
  @media screen and (min-width: 336px) {
    font-size: 2rem;
  }
  @media screen and (min-width: 369px) {
    font-size: 2.2rem;
  }
  @media screen and (min-width: 540px) {
    font-size: 3.4rem;
  }
  @media screen and (min-width: 784px) {
    font-size: 4.3rem;
  }
  a {
    color: ${titleColor};
    :hover {
      color: ${titleHoverColor};
    }
  }
`;

const SiteDescription = styled.div`
  color: #777;
  line-height: 1.4;
  font-size: 1.2rem;
  @media screen and (min-width: 336px) {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 369px) {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 540px) {
    font-size: 1.6rem;
  }
  @media screen and (min-width: 783px) {
    font-size: 2rem;
  }
`;

type Props = { title: string; description: string };

const Header: React.FC<Props> = ({ title, description }) => {
  return (
    <SiteHeader>
      <SiteBranding>
        <SiteTitle>
          <Link to='/'>{title}</Link>
        </SiteTitle>
        <SiteDescription>{description}</SiteDescription>
      </SiteBranding>
    </SiteHeader>
  );
};

export default Header;
