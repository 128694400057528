import styled from '@emotion/styled';
import { faRss } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { maxWidthForTwoColumns, twoColumnsBreakpoint } from '../design/css-variables';

const SiteFooter = styled.footer`
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;
  font-size: 1.4rem;
  font-style: italic;
  color: #999;

  a {
    color: #999;
    box-shadow: none;
  }
`;

const FooterContainer = styled.div`
  border-top: solid 1px #eee;
  padding-top: 24px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${twoColumnsBreakpoint}px) {
    max-width: ${maxWidthForTwoColumns}px;
  }
`;

const Footer: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
    }
  `);
  const authorName = data.site?.siteMetadata?.author?.name;
  const twitterAccount = data.site?.siteMetadata?.social?.twitter;
  const twitterUrl = twitterAccount ? `https://twitter.com/${twitterAccount}` : undefined;

  return (
    <SiteFooter>
      <FooterContainer>
        &copy; 2016-2020{' '}
        {twitterUrl ? (
          <a target='_blank' rel='noopener noreferrer' href={twitterUrl}>
            {authorName}
          </a>
        ) : (
          <span>{authorName}</span>
        )}
        <a href='/rss.xml' title='RSSフィード'>
          <FontAwesomeIcon icon={faRss} style={{ marginLeft: 16 }} />
        </a>
      </FooterContainer>
    </SiteFooter>
  );
};

export default Footer;
