import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

// 記事メタ情報に表示する日時（投稿日時、更新日時）
const ArticleMetaDate: React.FC<{ icon: IconDefinition; date: string }> = ({ icon, date }) => {
  return (
    <span style={{ marginRight: 16 }}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
      {date}
    </span>
  );
};

export default ArticleMetaDate;
