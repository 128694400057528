import styled from '@emotion/styled';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import React from 'react';

import { asideTextColor, linkColor, metaTextColor } from '../design/css-variables';
import ArticleMetaDate from './article-meta-date';

const RecentPostsContainer = styled.ul`
  list-style: none;
  padding: 0;

  li {
    /*
    border-top: solid 1px #eee;
    */
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: hidden;
  }
  li:first-of-type {
    /*
    border-top: none;
    */
    padding-top: 8px;
  }
  a {
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
    box-shadow: none;
  }
`;

const EyeCatch = styled.div`
  /* マウスホバーで画像ズームしてもはみ出した部分は切り取る */
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 16px;
  width: 80px;
  height: 80px;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    /* 画像の下に隙間ができないようにする */
    vertical-align: bottom;
    margin-bottom: 0;
  }

  /* マウスホバーで画像を拡大 */
  img {
    /* Gatsby が上書きするため!importantで防止 */
    transition: all 300ms 0s ease-in-out !important;
  }
  a:hover & img {
    transform: scale(1.08, 1.08);
  }
`;

const ArticleHeader = styled.div`
  font-size: 1.4rem;
  flex-shrink: 1;
`;

const ArticleTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${asideTextColor};
  margin: 0;
  a:hover & {
    color: ${linkColor};
  }
`;

const ArticleMeta = styled.div`
  margin-top: 4px;
  color: ${metaTextColor};
`;

const RecentPosts: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.RecentPostsQuery>(graphql`
    query RecentPosts {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 5) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY年M月D日")
              title
              description
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 80, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              featuredSVG
            }
          }
        }
      }
    }
  `);
  const posts = data.allMarkdownRemark.edges.map(edge => edge.node);

  return (
    <RecentPostsContainer>
      {posts.map(post => {
        const title = post.frontmatter?.title;
        const postDate = post.frontmatter?.date || '';
        const eyecatchImage = post.frontmatter?.featuredimage?.childImageSharp?.fluid || post.frontmatter?.featuredSVG;
        return (
          <li key={post.fields?.slug}>
            <Link to={post.fields?.slug || ''}>
              <EyeCatch>
                {eyecatchImage && typeof eyecatchImage !== 'string' && <Img fluid={eyecatchImage} />}
                {typeof eyecatchImage === 'string' && (
                  <img width='80' height='80' src={eyecatchImage} alt='アイキャッチ画像' />
                )}
              </EyeCatch>
              <ArticleHeader>
                <ArticleTitle>{title}</ArticleTitle>
                <ArticleMeta>
                  <ArticleMetaDate icon={faCalendar} date={postDate} />
                </ArticleMeta>
              </ArticleHeader>
            </Link>
          </li>
        );
      })}
    </RecentPostsContainer>
  );
};

export default RecentPosts;
