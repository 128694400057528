import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';

import { asideTextColor } from '../design/css-variables';

const ProfileContainer = styled.aside`
  text-align: center;
  line-height: 1.6;
  margin-bottom: 64px;
`;

const ProfileName = styled.div`
  margin-bottom: 16px;
`;

const ProfileSummary = styled.div`
  text-align: left;
  font-size: 1.4rem;
  color: ${asideTextColor};
  p {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const Profile: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.ProfileQuery>(graphql`
    query Profile {
      avatar: file(relativePath: { eq: "profile-pic.jpg" }) {
        childImageSharp {
          fixed(width: 136, height: 136) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);
  const fixed = data.avatar?.childImageSharp?.fixed;
  const authorName = data.site?.siteMetadata?.author?.name;
  const authorSummary = data.site?.siteMetadata?.author?.summary?.map(
    (line, index) => line && <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
  );

  return (
    <ProfileContainer>
      <Image
        fixed={fixed}
        css={css`
          border-radius: 50%;
          max-width: 136px;
          margin-bottom: 16px;
        `}
      ></Image>
      <ProfileName>{authorName}</ProfileName>
      <ProfileSummary>{authorSummary}</ProfileSummary>
    </ProfileContainer>
  );
};

export default Profile;
