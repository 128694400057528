/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

type Meta = {
  readonly name: string;
  readonly content: string;
};

type Props = {
  readonly title?: string;
  readonly description?: string;
  readonly meta?: readonly Meta[];
  readonly ogType?: string;
  readonly imagePath?: string;
  readonly tags?: readonly string[];
};
const SEO: React.FC<Props> = ({ description, meta, title, ogType, imagePath: imagePath, tags }) => {
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            lang
            siteUrl
            author {
              name
            }
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const siteName = site?.siteMetadata?.title;
  const metaDescription = description || site?.siteMetadata?.description;
  const lang = site?.siteMetadata?.lang;
  const twitter = site?.siteMetadata?.social?.twitter;
  const author = site?.siteMetadata?.author?.name;
  const type = ogType || 'website';
  const siteUrl = site?.siteMetadata?.siteUrl;
  const imageUrl = `${siteUrl}${imagePath}`;

  const jsonld =
    title === undefined
      ? {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          name: siteName,
          url: siteUrl,
          author: {
            '@type': 'Person',
            name: author,
          },
        }
      : {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          headline: title,
          description: description,
          image: imageUrl,
          editor: author,
          inLanguage: 'ja-JP',
          keywords: tags,
          // TODO: タグから keywords を
          // TODO: wordcount
          // TODO: url
          // TODO: datePublished
          // TODO: dateCreated
          // TODO: dateModified
          author: {
            '@type': 'Person',
            name: author,
          },
        };

  // TODO: 中身をどうするか考える。
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={title}
      defaultTitle={siteName}
      titleTemplate={`%s | ${siteName}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        // OpenGraph
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        // Twitter Card
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
      ].concat(meta === undefined ? [] : meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({ jsonld }),
        },
      ]}
    />
  );
};

export default SEO;
