import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import { animationDuration, timingFunction, twoColumnsBreakpoint } from '../design/css-variables';
import Profile from './profile';
import RecentPosts from './recent-posts';
import TagCloud from './tag-cloud';

const onlyTwoColumns = css`
  display: none;
  @media screen and (min-width: ${twoColumnsBreakpoint}px) {
    display: block;
  }
`;

const TableOfContentsSection = styled.div`
  position: sticky;
  top: 0;

  /* ２カラムでのみ表示 */
  ${onlyTwoColumns}
`;

const TableOfContentsContainer = styled.nav`
  overflow-y: auto;
  max-height: 100vh;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.3rem;
  }
  li li {
    margin: 0 0 0 8px;
    padding: 4px;
    line-height: 1.2;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    box-shadow: none;
    transition: box-shadow ${animationDuration}s ${timingFunction};
  }
  a:hover {
    box-shadow: 0 -1px 0px 0px inset;
  }
`;

const TableOfContents: React.FC<{ tableOfContents: string }> = ({ tableOfContents }) => {
  return (
    <TableOfContentsSection>
      <TableOfContentsContainer dangerouslySetInnerHTML={{ __html: tableOfContents }} />
    </TableOfContentsSection>
  );
};

const SidebarWrapper = styled.div`
  /* 目次をsticky対応させるため親要素の高さを確保する必要がある */
  height: 100%;

  h2 {
    font-size: 1.6rem;
    letter-spacing: 0.08rem;
    margin-bottom: 16px;
    margin-top: 40px;
  }
`;

type Props = {
  tableOfContents?: string;
};

const Sidebar: React.FC<Props> = ({ tableOfContents }) => {
  return (
    <SidebarWrapper>
      <Profile />
      <h2>最近の記事</h2>
      <RecentPosts />
      <h2>タグ一覧</h2>
      <TagCloud />
      {tableOfContents && (
        <>
          <h2 css={onlyTwoColumns}>目次</h2>
          <TableOfContents tableOfContents={tableOfContents} />
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
